import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import type { Account } from '@wilm/shared-types/account';
import type { Cart as CartType } from '@wilm/shared-types/cart';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import { deleteParam } from 'helpers/searchParamsHelper';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useAccount, useCart } from 'frontastic';
import useBundle from 'frontastic/hooks/useBundle';
import CartItem from './components/cart-item';
import type { CartProps } from './types';
import Login from '../authentication/login';
import Markdown from '../markdown';
import OrderSummary from '../order-summary';
import Wishlist from '../wishlist';
import scrollToError from 'helpers/utils/scrollToError';

const Cart: React.FC<CartProps> = ({
    paymentMethods,
    emptyStateDescription,
    inventoryErrorModalTitle,
    inventoryErrorModalTextContent,
    inventoryErrorModalButtonText,
    autoAddedMembershipModalTitle,
    notLoggedInContent,
    loggedInContent,
    autoAddedMembershipButtonText,
    removeMembershipModalTitle,
    removeMembershipTextContent,
    removeMembershipButtonText,
    registrationRedirectTextContent,
    registrationRedirectButtonText,
    registrationNoteContent,
    blockedCustomersModalTitle,
    blockedCustomersTextContent,
    blockedCustomersButtonText
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [redirectToCheckoutAfterLogin, setRedirectToCheckoutAfterLogin] = useState(false);
    const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
    const [isRemoveMembershipModalOpen, setIsRemoveMembershipModalOpen] = useState(false);
    const [discountDescription, setDiscountDescription] = useState('');

    const [data, setData] = useState<CartType | null>(null);
    const [totalItems, setTotalItems] = useState(0);

    const router = useRouter();
    const loginAndRedirectToCheckout = useCallback((redirect: boolean) => {
        setIsLoginModalOpen(true);
        setRedirectToCheckoutAfterLogin(redirect);
    }, []);

    const {
        isEmpty: isCartEmpty,
        totalItems: cartTotalItems,
        data: cartData,
        hasOutOfStockItems,
        membershipAutoRenewal,
        setAutoMembership,
        handleIsRenewableChange,
        getCartDiscountDescription,
        isCartInventoryAvailable,
        removeItem,
        updateItem,
        isLoading,
        inventoryCheck,
        validateDelegateSelection
    } = useCart();

    const [isEmpty, setIsEmpty] = useState(isCartEmpty);
    useEffect(() => {
        if (cartData) {
            setData(cartData);
            setIsEmpty(isCartEmpty);
        }
    }, [cartData, isCartEmpty]);

    useEffect(() => {
        if (cartTotalItems) {
            setTotalItems(cartTotalItems);
        }
        if (data && isEmpty) {
            setTotalItems(0);
            setData(null);
        }
    }, [cartTotalItems, data, isEmpty]);

    const { membershipAutoRenewal: membershipAutoRenewalSettings, enabledAutoMemberShip } = useBrandSettingsContext();

    const autoRenewalPolicy = useMemo(() => {
        return membershipAutoRenewalSettings?.autoRenewalPolicy ?? '';
    }, [membershipAutoRenewalSettings?.autoRenewalPolicy]);

    const { loggedIn, accountBlocked, account } = useAccount();
    const params = useSearchParams();
    const [delegateErrors, setDelegateErrors] = useState<string[]>([]);

    const [showBlockedModal, setShowBlockedModal] = useState(params?.get('blocked') === 'true');
    const closeBlockedModal = useCallback(() => {
        setShowBlockedModal(false);
    }, []);

    const isRedirectedFromCheckoutWithInventoryError = params?.get('inventoryError') === 'true';
    const [showInventoryErrorModal, setShowInventoryErrorModal] = useState(
        isRedirectedFromCheckoutWithInventoryError && !!inventoryCheck?.hasOutOfStockItems
    );

    const closeInventoryErrorModal = useCallback(() => {
        const paramsWithoutInventoryError = deleteParam('inventoryError', params.entries());
        router.replace('/' + paramsWithoutInventoryError);
        setShowInventoryErrorModal(false);
    }, []);

    const lineItems = data?.lineItems ?? [];

    const { nonBundleItems, bundleComponents } = useBundle(lineItems || []);

    const hasRequiredMembership = lineItems.some(lineItem => lineItem.variant?.attributes?.requiredProductsForPurchase?.length);
    const autoAddedMembership = lineItems.find(lineItem => lineItem.isAutoAdded && !lineItem.parentBundleProductLineItemId);

    const closeLoginModal = useCallback(() => setIsLoginModalOpen(false), []);
    const closeMembershipModal = useCallback(() => {
        setIsMembershipModalOpen(false);

        if (autoAddedMembership) {
            window.localStorage.setItem('autoAddedLineItemId', autoAddedMembership.lineItemId);
        }
    }, [autoAddedMembership]);
    const closeRemoveMembershipModal = useCallback(() => setIsRemoveMembershipModalOpen(false), []);

    const handleAutoMembership = async () => {
        await setAutoMembership();
    };

    const cartDiscountDescription = async () => {
        const res = await getCartDiscountDescription();
        if (res.description) setDiscountDescription(res.description);
    };

    useEffect(() => {
        if (enabledAutoMemberShip && hasRequiredMembership) {
            void handleAutoMembership();
        }
    }, [loggedIn, hasRequiredMembership, enabledAutoMemberShip]);

    useEffect(() => {
        const auto = window.localStorage.getItem('autoAddedLineItemId') !== autoAddedMembership?.lineItemId;
        setIsMembershipModalOpen(auto && autoAddedMembership !== undefined);
    }, [autoAddedMembership]);

    useEffect(() => {
        if (!isEmpty) void cartDiscountDescription();
    }, [isEmpty]);

    const navigateToCheckout = useCallback(() => {
        track({ type: TagsActionType.BEGIN_CHECKOUT, payload: { cart: data! } });
        router.push('/checkout');
    }, [router, data]);

    const goToCheckout = useCallback(
        async (account?: Account) => {
            const delegateErrors = validateDelegateSelection();

            if (delegateErrors?.length && account?.isB2B) {
                setDelegateErrors(delegateErrors);
                scrollToError();
                return;
            }
            if (!loggedIn && !account?.accountId) {
                loginAndRedirectToCheckout(true);
            } else {
                if (enabledAutoMemberShip) {
                    await setAutoMembership();
                }

                if (accountBlocked) {
                    setShowBlockedModal(true);
                } else if (await isCartInventoryAvailable()) {
                    navigateToCheckout();
                } else {
                    setShowInventoryErrorModal(true);
                }
            }
        },
        [
            loginAndRedirectToCheckout,
            navigateToCheckout,
            loggedIn,
            enabledAutoMemberShip,
            accountBlocked,
            delegateErrors,
            validateDelegateSelection
        ]
    );

    const onLogin = useCallback(
        async (account: Account) => {
            closeLoginModal();
            redirectToCheckoutAfterLogin && (await goToCheckout(account));
        },
        [redirectToCheckoutAfterLogin, closeLoginModal]
    );

    const autoAddedModalContent = useMemo(() => {
        return loggedIn ? loggedInContent : notLoggedInContent;
    }, [loggedIn]);

    return (
        <div className="relative bg-checkout-bg">
            <div className="flex flex-col lg:flex-row lg:items-start lg:gap-40 lg:p-40">
                <div className="lg:w-[68%]" data-cy={enabledAutoMemberShip ? 'enabled-auto-membership' : 'disabled-auto-membership'}>
                    <div className="bg-white px-16 py-12 md:px-24 md:py-12 lg:px-20 lg:py-36 xl:px-40">
                        <div className={`pb-10 ${!isEmpty ? 'border-checkout-border border-b' : ''}`}>
                            <h3 className="mb-10 text-20 lg:text-l">
                                {formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' })}
                                {(data ?? (!isLoading && !isEmpty)) && (
                                    <Typography className="pl-5 text-secondary-black" as="span">
                                        {totalItems <= 1
                                            ? formatCartMessage({
                                                  id: 'singleItem',
                                                  values: { total: totalItems },
                                                  defaultMessage: '({total} Item)'
                                              })
                                            : formatCartMessage({
                                                  id: 'items',
                                                  values: { total: totalItems },
                                                  defaultMessage: '({total} Items)'
                                              })}
                                    </Typography>
                                )}
                            </h3>
                            {discountDescription && <div className="font-bold text-accent-1">{discountDescription}</div>}
                        </div>

                        {(data ?? (!isLoading && !isEmpty)) && (
                            <div
                                className="border-checkout-border divide-checkout-border divide-y border-t lg:border-none"
                                data-cy="products-in-cart"
                            >
                                {nonBundleItems.map(lineItem => (
                                    <div key={lineItem.lineItemId}>
                                        <CartItem
                                            item={lineItem}
                                            handleIsRenewableChange={handleIsRenewableChange}
                                            membershipAutoRenewal={membershipAutoRenewal}
                                            autoRenewalPolicy={autoRenewalPolicy}
                                            setIsRemoveMembershipModalOpen={setIsRemoveMembershipModalOpen}
                                            bundleItems={bundleComponents[lineItem.lineItemId]}
                                            isCart={true}
                                            removeItemFromCart={removeItem}
                                            updateItem={updateItem}
                                            inventoryCheck={inventoryCheck}
                                            hasDelegateErrors={delegateErrors?.includes(lineItem.lineItemId)}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {!isLoading && isEmpty && (
                            <p data-cy="empty-cart-message">
                                {emptyStateDescription ?? formatCartMessage({ id: 'cart.empty', defaultMessage: 'Your cart is empty.' })}
                            </p>
                        )}

                        {!data && isLoading && <Skeleton className="mt-16 h-30 w-full" />}
                    </div>
                    <Wishlist />
                </div>

                <div
                    className="border-checkout-border border bg-white py-12 md:py-12 lg:mt-0 lg:w-[30%] lg:border-0 lg:py-36"
                    data-cy="order-summary"
                >
                    <div className="px-16 pb-16 md:px-24 lg:px-36">
                        <h4 className="text-18">{formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}</h4>
                        {!isLoading && !loggedIn && (
                            <>
                                <p className="border-checkout-border mt-10 border-t pb-24 pt-20 leading-[20px]  text-secondary-black lg:mt-18 lg:border-none lg:pt-0">
                                    {formatCartMessage({
                                        id: 'order.summary.login',
                                        defaultMessage: 'Log in to use your personal offers!'
                                    })}
                                </p>
                                <Button
                                    variant="secondary"
                                    onClick={() => loginAndRedirectToCheckout(false)}
                                    size="full"
                                    data-cy="login-button-cart"
                                >
                                    {formatAccountMessage({ id: 'sign.in', defaultMessage: ' Login in' })}
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="px-16 md:px-24 lg:px-36">
                        <OrderSummary
                            classNames={{
                                applyDiscountButton: 'py-14 text-base',
                                infoContainer: 'pt-16 pb-18 text-base'
                            }}
                            isCartPage={true}
                            paymentMethods={paymentMethods}
                            button={
                                <Button
                                    variant="primaryCta"
                                    disabled={isEmpty || hasOutOfStockItems || isLoading}
                                    className="mt-16 hidden md:block"
                                    size="full"
                                    type="submit"
                                    data-cy="checkout-link"
                                    onClick={async () => {
                                        await goToCheckout(account);
                                    }}
                                >
                                    {formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
                                </Button>
                            }
                            hasOutOfStockItems={hasOutOfStockItems}
                            asSkeleton={isLoading}
                            isEmpty={isEmpty}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>

            <div className="border-checkout-border sticky bottom-0 w-full border-t bg-white p-16 md:hidden">
                <Button
                    disabled={!isLoading && isEmpty}
                    size="full"
                    variant="primaryCta"
                    onClick={async () => {
                        await goToCheckout(account);
                    }}
                >
                    {formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' })}
                </Button>
            </div>

            {!isEmpty && (
                <p className="block  bg-white p-16 text-md md:hidden">
                    {formatCartMessage({
                        id: 'tax.disclaimer',
                        defaultMessage: 'The tax amount will be calculated on the Payment step of the Checkout'
                    })}
                </p>
            )}

            <Modal
                isOpen={isLoginModalOpen}
                onRequestClose={closeLoginModal}
                className="relative w-[90%] overflow-hidden rounded-md bg-white focus-visible:outline-none"
                preventScroll={true}
                style={{ content: { maxWidth: '600px' } }}
                closeTimeoutMS={200}
                closeButton
            >
                <div className="max-h-screen overflow-auto  p-1 pb-36 pt-27 md:py-43">
                    <div className="m-auto grid max-w-[470px] px-20 md:px-0">
                        <Login
                            onLogin={onLogin}
                            registrationRedirectTextContent={registrationRedirectTextContent}
                            registrationRedirectButtonText={registrationRedirectButtonText}
                            registrationNoteContent={registrationNoteContent}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showInventoryErrorModal}
                className="relative w-[90%] rounded-md bg-white"
                style={{ content: { maxWidth: '545px' } }}
                closeTimeoutMS={200}
            >
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    <h3 className="text-center text-18 leading-[18px]">{inventoryErrorModalTitle}</h3>
                    <Markdown markdown={inventoryErrorModalTextContent ?? ''} className="markdown" />
                    <Button variant="secondary" size="full" onClick={closeInventoryErrorModal} className="rounded-md md:rounded-lg">
                        {inventoryErrorModalButtonText}
                    </Button>
                </div>
            </Modal>
            {autoAddedMembershipModalTitle && loggedInContent && notLoggedInContent && autoAddedMembershipButtonText && (
                <Modal
                    isOpen={isMembershipModalOpen}
                    className="relative w-[90%] rounded-md bg-white"
                    style={{ content: { maxWidth: '480px' } }}
                    closeTimeoutMS={200}
                    preventScroll
                >
                    <div className="flex max-h-[calc(100dvh-100px)] flex-col gap-25 overflow-auto p-15 text-base leading-6 md:gap-20 md:p-40">
                        <h3 className="mb-6 text-center text-18 leading-[18px]">{autoAddedMembershipModalTitle}</h3>
                        <Markdown markdown={autoAddedModalContent} className="markdown text-md" />
                        <Button
                            data-cy="close-membership-modal"
                            size="full"
                            onClick={closeMembershipModal}
                            className="rounded-md md:rounded-lg"
                        >
                            {autoAddedMembershipButtonText}
                        </Button>
                    </div>
                </Modal>
            )}

            {accountBlocked && (
                <Modal
                    isOpen={showBlockedModal}
                    className="relative w-[90%] rounded-md bg-white"
                    style={{ content: { maxWidth: '545px' } }}
                    closeTimeoutMS={200}
                >
                    <div className="flex flex-col gap-[25px] p-[15px] text-base leading-6 md:gap-20 md:p-40">
                        <h3 className="text-center text-[18px] leading-[18px]">{blockedCustomersModalTitle}</h3>
                        <Markdown markdown={blockedCustomersTextContent ?? ''} className="markdown" />
                        <Button variant="secondary" size="full" onClick={closeBlockedModal} className="rounded-md md:rounded-lg">
                            {blockedCustomersButtonText}
                        </Button>
                    </div>
                </Modal>
            )}

            {(removeMembershipButtonText ?? removeMembershipTextContent ?? removeMembershipModalTitle) && (
                <Modal
                    isOpen={isRemoveMembershipModalOpen}
                    className="relative w-[90%] rounded-md bg-white"
                    style={{ content: { maxWidth: '545px' } }}
                    closeTimeoutMS={200}
                >
                    <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                        {removeMembershipModalTitle && <h3 className="text-center text-18 leading-[18px]">{removeMembershipModalTitle}</h3>}
                        {removeMembershipTextContent && <Markdown markdown={removeMembershipTextContent} className="markdown" />}
                        <Button variant="secondary" size="full" onClick={closeRemoveMembershipModal} className="rounded-md md:rounded-lg">
                            {removeMembershipButtonText}
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Cart;
